import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
console.log(process.env);
const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers["x-auth-token"] = localStorage.getItem("token") || "";
  return config;
});

export default axiosInstance;
