import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Loader from "../../components/Loader/Loader";
import { AiOutlineRight } from "react-icons/ai";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import AtoZCards from "../atozCards/AtoZCards";
import FooterApp from "../../components/appAdminLayout/FooterApp";
import { errorMsg } from "../../Helpers/Error";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import axiosInstance from "../../Hooks/api/Api";

const QuizInside = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [topics, setTopics] = useState([]);
  const [error, setError] = useState("");
  const [search, setSearch] = useState({});
  const [sort, setSort] = useState(-1);
  let { cid } = useParams();
  const pageName = "Explore";

  const [courseName, setcourseName] = useState("");

  const getTopics = (search) => {
    setLoader(true);
    axiosInstance
      .post("courses/listTopic/" + cid, search)
      .then((data) => {
        setLoader(false);
        setTopics(
          data.data.data.sort(function (a, b) {
            return sortArray(b.name, a.name);
          })
        );
        setcourseName(data.data.data[0].category.name);
        sortData();
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };
  const sortData = () => {
    sort === -1 ? setSort(1) : setSort(-1);
    if (sort === -1) {
      topics.sort(function (a, b) {
        return sortArray(b.name, a.name);
      });
    }
    if (sort === 1) {
      topics.sort(function (a, b) {
        return sortArray(a.name, b.name);
      });
    }
  };

  const sortArray = (a, b) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  };

  useEffect(() => {
    getTopics(search);
  }, [search]);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <HeaderApp pageName={pageName}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative mb-5">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div className="d-flex justify-content-start align-items-center">
                <IoIosArrowBack
                  className="square-25 text-white bg-button-color-1 rounded-1 me-2"
                  onClick={() => navigate(-1)}
                ></IoIosArrowBack>
                <h6 className=" fw-bold text-capitalize color-button-color-1 mb-0">
                  {"Test's"}
                </h6>
              </div>
            </div>
                <div
                  className="d-flex justify-content-start mb-2 gap-3 align-items-center px-3 c-pointer"
                >
                    <h2
                      className="color-button-color-1 fw-bold font-size-8x"
                    >
                    {"0"}1
                    </h2>
                  <div className="flex-grow-1">
                    <small className="fw-bold text-break color-button-color-1">
                      Mock - C Programming Test
                    </small>
                    <small className="text-secondary append-ellipsis">
                      10 Questions | 30 Mins
                    </small>
                  </div>
                  <button
                    className="btn btn-sm rounded-1 btn-button-color-1 text-light"
                  >
                    <AiOutlineRight className="fs-6"></AiOutlineRight>
                  </button>
                </div>
          </Card>
        </Container>
        <FooterApp></FooterApp>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default QuizInside;
