import React, { useEffect, useRef, useState } from "react";
import Editor from "@monaco-editor/react";
import { Image } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import { errorMsg } from "../../Helpers/Error";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstanceq from "../../Hooks/api/hackApiQ";
import axiosInstanceh from "../../Hooks/api/hackApi";
import { IoIosArrowBack } from "react-icons/io";

const LanguageEditor = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // const pageName = "Explore";
  const [language, setLanguage] = useState("");
  // const [code, setCode] = useState([]);
  // const [output, setOutput] = useState("");
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const contestId = localStorage.getItem("contestId");
  const [error, setError] = useState("");
  const [queData, setQuestionData] = useState({});
  const [testCaseData, setTestCaseData] = useState([]);
  const [evaluationData, setEvaluationData] = useState([]);
  const [codeContent, setCodeContent] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  let evalautionCaseDataIDs = {};
  
  const handleLanguageChange = (event) => setLanguage(event.target.value);

  let { editorid } = useParams();
  const editorRef = useRef(null);
  function handleEditorDidMount(editor, monaco) {
    // here is the editor instance
    // you can store it in `useRef` for further usage
    editorRef.current = editor;
  }
  

  const handleEditorChange = (value) => setCodeContent(value);
  const options = {
    minimap: {
      enabled: false,
    },
    language : 'javascript',
    colorDecorators : true,
    colorDecoratorsLimit : 5,
    defaultColorDecorators : true,
    bracketPairColorization : {enabled : true},
    codeLens : true,
    theme : "vs-light"
  };
const reEvaluate = async (submissionId) => {
  let evaluationReport = await axiosInstanceh.get("/api/v1/evaluation/"+submissionId);
      let evalautionCaseData = evaluationReport['data']['Data'];
      setEvaluationData(evalautionCaseData);
}
  const handleRunCode = async (isFinal = false) => {
    try {
      setLoader(true);
      const selectedTestCase = testCaseData.find(
        (testCase) => testCase.tabName === activeTab
      );
      const stdinValue = selectedTestCase?.input || "";
      const data = {
        contestId: contestId,
        questionId: queData._id,
        participantId: userData.phoneNo,
        codeContent: {
          language: language.toUpperCase(),
          isMultiFile: false,
          singleFileContent: codeContent,
          multiFileContent: {},
          base64Encoded: false,
          status: "SAVED",
        },
        stdin: stdinValue,
        isFinal,
        evaluateSampleCases: !isFinal,
      };

      const response = await axiosInstanceh.post("api/v1/submission", data);
      setSubmitted(true);
      // const success = "Submitted successfully";
      // setSuccessMsg(success);
      // console.log("API Response:", response.data);
      const submissionData = response['data']['Data'];
      if (isFinal) {
       
        setTimeout(() => {
          navigate("/user/home");
        }, 2000);
      } else {
        let evaluationReport = await axiosInstanceh.get("/api/v1/evaluation/"+submissionData['_id']);
        let evalautionCaseData = evaluationReport['data']['Data'];
        setEvaluationData(evalautionCaseData);
        setTimeout(() => {
          const success = "";
          setSuccessMsg(success);
        }, 2000);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      // console.error("Submission Error:", error);
      setSubmitted(false);
      setError(errorMsg(error));
      setTimeout(() => setError(""), 2000);
    }
  };

  const [activeTab, setActiveTab] = useState("Case 1");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    const fetchEventAndQuestions = async () => {
      try {
        setLoader(true);
        const response = await axiosInstanceq.get(
          `api/v1/question/${editorid}`
        );
        const questionData = response.data.Data._doc;
        setQuestionData(questionData);
        if(questionData['supportedLanguages'] && questionData['supportedLanguages'].length > 0){
        setLanguage(questionData['supportedLanguages'][0])
        }
        // console.log(questionData);
        // console.log(response.data.Data.testCases);
        if (response.data.Data.testCases.length) {
          let testCaseData = response.data.Data.testCases;
          testCaseData =testCaseData.filter((obj)=> !obj['softDelete'] && !obj['isHidden']);
          testCaseData = testCaseData.map(
            (testCase, index) => ({
              ...testCase,
              tabName: `Case ${index + 1}`,
            })
          );
          console.log(testCaseData);
          setTestCaseData(testCaseData);
          setActiveTab(testCaseData[0]?.tabName);
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => setError(""), 2000);
      }
    };

    fetchEventAndQuestions();
  }, [editorid]);
  
  return (
    <>
      <div className="bg-light">
        {loader && <Loader></Loader>}
        <div className="msg d-flex justify-content-center align-items-center mb-2 ">
          {successMsg && (
            <div className="font-size-3x color-theme-color-3">{successMsg}</div>
          )}
          {error && <div className="font-size-3x text-danger">{error}</div>}
        </div>
        <div className="d-flex justify-content-center align-items-center bg-theme-gray-14 p-2">
          <div className="run-section">
            <img src="https://lms-coignite.aarshilearning.com/assets/imgs/learnathon.png" className="editorLogo"/>
            <IoIosArrowBack
              className="square-25 text-white bg-button-color-1 rounded-1 me-2"
              onClick={() => navigate(-1)}
            ></IoIosArrowBack>
            <button
              className="btn bg-theme-gray-13 m-2"
              onClick={() => handleRunCode(false)}
            >
              <i className="fas fa-play font-size-2x me-1"></i>
              <span className="font-size-2x">Run</span>
            </button>
            <button
              className="btn bg-theme-gray-13 me-2"
              onClick={() => handleRunCode(true)}
            >
              <i className="fa fa-cloud-download me-2 color-theme-color-3"></i>
              <span className="font-size-2x color-theme-color-3">Submit</span>
            </button>
           
          </div>
        </div>
        <div className="coding-test-container">
          <div className="question-panel">
            <div className="question">
              <div className="d-flex justify-content-between align-items-center">
                <div className="questtile font-size-4x mb-3 color-theme-black">
                  1.
                  <span className="ms-2 font-size-2x">
                    {queData && queData.title ? queData.title : ""}
                  </span>
                </div>
                {/* <div className=" mb-3 font-size-2x">
                  Solved
                  <span className="ms-1">
                    <i className="far fa-check-circle color-theme-color-3"></i>
                  </span>
                </div> */}
              </div>
              <div className="quest description">
                <div
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html:
                      queData && queData.description
                        ? queData.description["value"]
                        : "",
                  }}
                ></div>
                <div
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html:
                      queData && queData.instruction
                        ? queData.instruction["value"]
                        : "",
                  }}
                ></div>
              </div>
            </div>
            <div className="examples mt-3">
              <div className="ex-1 mb-3">
                <h5 className="font-size-3x">Example</h5>
                <div className="borderleft">
                  <div className="content ms-2">
                    <div className="ex1input">
                      Input:
                      <span className="color-theme-gray-9">
                        {queData && queData.sampleInput
                          ? queData.sampleInput["value"]
                          : ""}
                      </span>
                    </div>
                    <div className="ex1output">
                      Output:{" "}
                      <span className="color-theme-gray-9">
                        {" "}
                        {queData && queData.sampleOutput
                          ? queData.sampleOutput["value"]
                          : ""}
                      </span>
                    </div>
                    <div className="ex1explain">
                      Explanation:
                      <span className="color-theme-gray-9">
                        {queData && queData.explanation
                          ? queData.explanation["value"]
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mt-3">
                <h5 className="font-size-3x">Constraints:</h5>
                <div className="">
                  <ul className="">
                    <li className="p-2 bg-theme-gray-14 mb-2">2</li>
                    <li className="p-2 bg-theme-gray-14 mb-2">2</li>
                    <li className="p-2 bg-theme-gray-14">2</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>

          <div className="editor-panel">
            <div className="d-flex align-items-center">
              <div className="language-select mb-2">
                <select
                  onChange={handleLanguageChange}
                  className="border-0 langbg color-theme-gray-9 me-2"
                  value={language}
                >
                  {queData?.supportedLanguages?.map((lang, index) => (
                    <option key={index} value={lang}>
                      {lang}
                    </option>
                  )) || (
                    <option value="" disabled>
                      No languages available
                    </option>
                  )}
                </select>
              </div>
              <div className="mt-2">
              </div>
            </div>

            <div style={{ height: "300px", marginBottom: "10px" }}>
              <Editor
                height="100%"
                language={language}
                options = {options}
                theme="vs-light"
                value={codeContent}
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
              />
            </div>
            <div className="output-panel bg-light">
              <div className="d-flex justify-content-start align-items-center mb-2">
                <div className="d-flex justify-content-start align-items-center me-3">
                  <i className="fas fa-check-square me-2 color-theme-gray-11"></i>
                  <div className="color-theme-gray-8">TestCase</div>
                </div>
                <div className="d-flex justify-content-start align-items-center">
                  <i className="fas fa-chevron-right color-theme-color-3"></i>
                  <i className="fas fa-minus  color-theme-color-3 minus me-2"></i>
                  <div className="color-theme-black">Test Result</div>
                </div>
              </div>
              <div className="row justify-content-center">
               
                <div className="tab_case mt-3">
                  <div className="bg-theme-color-10 d-flex justify-content-start align-items-center tab-case-container">
                    {testCaseData.map((testCase) => (
                      
                      <button
                        key={testCase._id}
                        className={`btn ${
                          activeTab === testCase.tabName
                            ? "active text-center"
                            : "inactive text-center"
                        }`}
                        onClick={() => handleTabClick(testCase.tabName)}
                      >
                        <i
                          className={`fas fa-circle ${
                            activeTab === testCase.tabName
                              ? "color-green"
                              : "color-gray"
                          } font-size-5px me-2`}
                        />
                        {testCase.tabName}
                      </button>
                    ))}
                  </div>
                  <div className="tab-content mt-3">
                    {testCaseData.map(
                      (testCase) =>
                        activeTab === testCase.tabName && (
                          <div key={testCase._id}>
                            {evaluationData && evaluationData.map((obj) => 
                            obj['testCaseId'] === testCase['_id'] && (
                             <div  key={obj['_id']}  className={obj['status_id'] === 3 ? 'color-theme-color-3 font-size-3x' :'text-danger font-size-3x'}>
                                {obj['status_id'] === 3  ? "ACCEPTED" : (obj['status_id'] < 10 && obj['status'] !='Accepted' ? "RETRY ("+obj['status']+")" : "ERROR")} 
                                
                                {obj['status_id'] < 10 && obj['status_id'] != 3 && (
                                   <button key={"button_"+obj['_id']}
                                   className="btn bg-theme-gray-13 me-2"
                                   onClick={() => reEvaluate(obj['submissionId'])}
                                 >
                                   <i className="fa fa-refresh me-2 color-theme-color-3"></i>
                                 </button>
                                )}
                                 {obj['stderr'] && (
                                   <span key={"error_"+obj['_id']}
                                   className="me-2 text-danger"
                                   
                                 >
                                   {obj['stderr']}
                                 </span>
                                )}
                                
                </div>
                            ))}
                            <div className="inputs">
                              <label className="">Input</label>
                              <div className="bg-theme-gray-14 p-2">
                                <div
                                  className="mb-2"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      testCase && testCase.input
                                        ? testCase.input
                                        : "",
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="output mt-3">
                              <label className="">Output</label>
                              <div className="bg-theme-gray-14 p-2">
                                <div className="">{testCase.output}</div>
                              </div>
                            </div>
                            {evaluationData && evaluationData.map((obj) => 
                            obj['testCaseId'] === testCase['_id'] && (
                            <div className="output mt-3">
                              <label className="">Result</label>
                              <div className="bg-theme-gray-14 p-2">
                                <div className="">{obj['stdout']}</div>
                              </div>
                            </div>
                          ) )}
                           </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LanguageEditor;
