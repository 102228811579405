import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import axiosInstance from "../../Hooks/api/Api";
import { errorMsg } from "../../Helpers/Error";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import Loader from "../../components/Loader/Loader";
import FooterApp from "../../components/appAdminLayout/FooterApp";

import HomeCard from "./HomeCard";
import HomeSlide from "./HomeSlide";
import HomeStories from "./HomeStories";
import { useNavigate, NavLink } from "react-router-dom";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import axiosInstanceh from "../../Hooks/api/hackApi";

const HomePage = () => {
  const navigate = useNavigate();

  const pageName = "Home";
  const [currentCourses, setCurrentCourses] = useState([]);
  const [categoriesTopics, setCategoriesTopic] = useState([]);
  const [quizes, setQuizes] = useState([]);
  const [hackathon, setHackathon] = useState([]);
  const [banners, setBanners] = useState([]);
  const [error, setError] = useState("");
  const [searchData, setSearchData] = useState({});
  const [loader, setLoader] = useState(false);
  const getCurrentCourses = (search) => {
    setLoader(true);
    axiosInstance
      .post("courses/onGoingCourses", search)
      .then((response) => {
        setCurrentCourses(response.data.data);
        // console.log(currentCourses);
        getBanners(search);
        getCategoriesTopics();
        getQuizes();
        getHackathon();
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const getCategoriesTopics = () => {
    axiosInstance.get("courses/getCategoryTopics").then((response) => {
      // console.log(response.data.data);
      setCategoriesTopic(response.data.data);
    });
  };
  const getQuizes = () => {
    // axiosInstance.get("courses/getCategoryTopics").then((response) => {
    // setCategoriesTopic(response.data.data);
    // });
    setQuizes([
      {
        _id: "66ab3db4eba17e999778b97b",
        name: "Information Technology & Communication",
        topics: [
          {
            _id: "66ab3db4eba17e999778b97b",
            name: "Coding",
            description: "Coding",
          },
        ],
        description: "Information Technology & Communication",
      },
    ]);
  };

  const getHackathon = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    axiosInstanceh.post("api/v1/results/all", {
      "filters":{
          "participantId" : userDetails["phoneNo"]
      },
  "options":{}
  }).then((results) => {
    const submissionResults = results['data']['Data']['results'];
    const submittedContestIds = submissionResults.map((obj) => obj['contestId']);
    console.log(submittedContestIds);
    axiosInstanceh.post("api/v1/enrollment/all", {
        "filters":{
            "participantId" : userDetails["phoneNo"]
        },
    "options":{}
    }).then((response) => {
      const enrollments = response.data.Data && response.data.Data['enrollments'] ? response.data.Data['enrollments'] : [];
      let contests = [];
      enrollments.map((obj)=> {if(obj['status'] === 'ACCEPTED' && submittedContestIds.indexOf(obj['contest']['_id'].toString()) < 0) { console.log(obj['contest']['_id']); contests.push(obj['contest']) }});
      // console.log(data["contests"]);
      setHackathon(contests);
      // console.log(response);
      // console.log(hackathon);
    });
  });
  };

  const getBanners = (search) => {
    axiosInstance.post("banners/bannerList", search).then((response) => {
      setBanners(response.data.data);
    });
  };

  useEffect(() => {
    localStorage.removeItem("contestId");
    getCurrentCourses(searchData);
  }, []);

  const editor = () => {
    navigate("/user/lang-editor");
  };

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}

        <HeaderApp pageName={pageName}></HeaderApp>

        <Container id="homePage" fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100">
            <Card.Body>
              <HomeSlide data={banners}></HomeSlide>

              <div>
                {currentCourses.length !== 0 && (
                  <h6 className=" fw-bold color-theme-color-1 mt-2">
                    Ongoing Courses
                  </h6>
                )}

                <div
                  className="d-flex overflow-auto mt-2"
                  style={{ gap: "10px" }}
                >
                  {currentCourses.map((course) => (
                    <HomeCard
                      id={course._id}
                      key={course._id}
                      image={course.media}
                      name={course.name}
                      cls={course.category.name}
                      subject={course.topic.name}
                    ></HomeCard>
                  ))}
                </div>
              </div>
              <div className="my-2 pb-5">
                {categoriesTopics.map((topic) => (
                  <div
                    className="d-flex flex-column align-items-start mb-2"
                    key={topic["_id"]}
                  >
                    <h6 className="color-button-color-1 fw-bold">
                      {topic["name"] ? topic["name"] : "NA"}
                    </h6>
                    <div className="d-flex justify-content-start flex-wrap gap-2">
                      {topic["topics"].map((item) => (
                        <HomeStories
                          courseID={item["_id"]}
                          key={item["_id"]}
                          name={item["name"]}
                          quiz={false}
                          hackathon={false}
                        ></HomeStories>
                      ))}
                    </div>
                  </div>
                ))}
                <div className="d-flex flex-column align-items-start mb-2">
                  <h6 className="color-button-color-1 fw-bold">
                    Test & Quizzes
                  </h6>
                </div>
                {quizes.map((topic) => (
                  <div
                    className="d-flex flex-column align-items-start mb-2"
                    key={topic["_id"]}
                  >
                    <h6 className="color-button-color-1 fw-bold">
                      {topic["name"] ? topic["name"] : "NA"}
                    </h6>
                    <div className="d-flex justify-content-start flex-wrap gap-2">
                      {topic["topics"].map((item) => (
                        <HomeStories
                          courseID={item["_id"]}
                          key={item["_id"]}
                          name={item["name"]}
                          quiz={true}
                        ></HomeStories>
                      ))}
                    </div>
                  </div>
                ))}
              {hackathon && hackathon.length > 0 && (
               <div>
               <div className="d-flex flex-column align-items-start mb-2">
                  <h6 className="color-button-color-1 fw-bold">
                    Hackathon Events
                  </h6>
                </div>

                <div className="d-flex flex-column align-items-start mb-2">
                  <h6 className="color-button-color-1 fw-bold">Hackathon</h6>
                  <div className="d-flex justify-content-start flex-wrap gap-2">
                    {hackathon.map((item) => (
                      <HomeStories
                        eventId={item["_id"]}
                        key={item["_id"]}
                        name={item["name"]}
                        hackathon={true}
                      ></HomeStories>
                    ))}
                  </div>
                </div>
                </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Container>
        <FooterApp></FooterApp>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default HomePage;
