import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Loader from "../../components/Loader/Loader";
import { AiOutlineRight } from "react-icons/ai";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import FooterApp from "../../components/appAdminLayout/FooterApp";
import { errorMsg } from "../../Helpers/Error";
import axiosInstanceh from "../../Hooks/api/hackApi";
import axiosInstanceq from "../../Hooks/api/hackApiQ";

const HackathoPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [event, setEventDetails] = useState({});
  const [questionList, setQuestiontDetails] = useState([]);
  const [error, setError] = useState("");
  let { cid } = useParams();
  const pageName = "Explore";

  useEffect(() => {
    const fetchEventAndQuestions = async () => {
      try {
        setLoader(true); const userDetails = JSON.parse(localStorage.getItem("userDetails"));
        axiosInstanceh.post("api/v1/results/all", {
          "filters":{
              "participantId" : userDetails["phoneNo"],
              "contestId" : cid
          },
      "options":{}
      }).then(async (results) => {
        const resultSubmissionData = results['data']['Data']['results'];
        if(resultSubmissionData && !resultSubmissionData.length){
        const eventResponse = await axiosInstanceh.get(`api/v1/contest/${cid}`);
        const eventData = eventResponse.data.Data;
        setEventDetails(eventData);
        // console.log(eventData);
        if (eventData && eventData.questions) {
          const data = {
            options: { skip: 0, limit: 100 },
            filter: { _id: { $in: eventData.questions } },
          };

          localStorage.setItem("contestId", cid);

          const questionsResponse = await axiosInstanceq.post(
            "api/v1/question/all",
            data
          );
          setQuestiontDetails(questionsResponse.data.Data.questions);
        }

        setLoader(false);
        }else{
          setLoader(false);
          navigate("/user/home");
        }
      });
      } catch (error) {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => setError(""), 2000);
      }
    };

    fetchEventAndQuestions();
  }, [cid]);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <HeaderApp pageName={pageName}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative mb-5">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <div className="d-flex justify-content-start align-items-center">
                <IoIosArrowBack
                  className="square-25 text-white bg-button-color-1 rounded-1 me-2"
                  onClick={() => navigate(-1)}
                ></IoIosArrowBack>
                <h6 className=" fw-bold text-capitalize color-button-color-1 mb-0">
                  {"Hackathon"}
                </h6>
              </div>
            </div>
            {questionList.length > 0 &&
              questionList.map((que, index) => (
                <div
                  className="d-flex justify-content-start mb-2 mt-3 gap-3 align-items-center c-pointer"
                  key={que["_id"]}
                >
                  <h2 className="color-button-color-1 fw-bold font-size-8x">
                    {index >= 9 ? index * 1 + 1 : "0" + (index * 1 + 1)}
                  </h2>
                  <div className="flex-grow-1">
                    <small className="fw-bold text-break color-button-color-1">
                      {que["title"] ? que["title"] : "NA"}
                    </small>
                    <small className="text-secondary append-ellipsis">
                      {/* {questionList.length} Questions |{" "}
                      {Math.round(
                        (60 * event.duration.value) / questionList.length
                      )}{" "}
                      Mins */}
                    </small>
                  </div>
                  <button
                    className="btn btn-sm rounded-1 btn-button-color-1 text-light"
                    onClick={() => navigate("/user/lang-editor/" + que._id)}
                  >
                    <AiOutlineRight className="fs-6"></AiOutlineRight>
                  </button>
                </div>
              ))}

            {/* <div className="d-flex justify-content-start mb-2 gap-3 align-items-center px-3 c-pointer">
              <h2 className="color-button-color-1 fw-bold font-size-8x">
                {"0"}1
              </h2>
              <div className="flex-grow-1">
                <small className="fw-bold text-break color-button-color-1">
                  Coding Kings HackathonTest
                </small>
                <small className="text-secondary append-ellipsis">
                  10 Questions | 30 Mins
                </small>
              </div>
              <button
                className="btn btn-sm rounded-1 btn-button-color-1 text-light"
                onClick={editor}
              >
                <AiOutlineRight className="fs-6"></AiOutlineRight>
              </button>
            </div> */}
          </Card>
        </Container>
        <FooterApp></FooterApp>
      </div>
    </>
  );
};

export default HackathoPage;
