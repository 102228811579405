import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URLQ;
const axiosInstanceq = axios.create({
  baseURL: apiUrl,
});

axiosInstanceq.interceptors.request.use((config) => {
  return config;
});

export default axiosInstanceq;
