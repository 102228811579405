import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URLO;
const axiosInstanceh = axios.create({
  baseURL: apiUrl,
});

axiosInstanceh.interceptors.request.use((config) => {
  return config;
});

export default axiosInstanceh;
